<template>
  <v-app-bar flat color="transparent animated-gradient" class='translucid' :elevation="elevation" style='z-index:50;'>

    <v-container class='mx-auto d-flex align-center justify-center'>
      <v-app-bar-nav-icon :icon='drawerIcon' color=secondary v-on:click="emitToggle"></v-app-bar-nav-icon>
      <!--<v-app-bar-title @click="drawer = !drawer">Menù</v-app-bar-title>-->
      <v-spacer></v-spacer>
    </v-container>

    <slot></slot> <!-- Slot default per il contenuto personalizzato -->
  </v-app-bar>
</template>

<script setup lang="ts">
// Import statements and any other setup-specific code can go here
</script>

<script lang='ts'>
export default {
  props: {
    propDrawer: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    drawerIcon() {
      return '$' + (this.propDrawer ? 'chevron-left' : 'chevron-right');
    }
  },
  data() {
    return {
      elevation: 0,
    };
  },
  methods: {
    handleScroll() {
      const scrollY = window.scrollY;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = scrollY / maxScroll;
      this.elevation = Math.min(Math.max(scrollPercentage, 0), 1);
      this.elevation = this.elevation > 0.2 ? 1 : 0;
    },
    emitToggle(e) {
      //this.drawer = !this.propDrawer;
      this.$emit('update-data', !this.propDrawer);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.translucid {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(11px) grayscale(30%);
}
</style>
