<template>
  <v-card width="450" rounded='xl' class="mx-auto pa-5" elevation="2">

    <v-card-title class='text-center text-h4 font-weight-bold'>LOGIN</v-card-title>

    <v-form v-model="formValid" @submit.prevent="login" :disabled='$api.isLoading'>
      <v-card-item>
        <!-- USERNAME/EMAIL -->
        <div class="text-subtitle-1">Account</div>
        <v-text-field v-model="username" color="primary" name="username" type="email" :rules="[rules.required]"
          :readonly='$api.isLoading' clearable autocomplete='username' class="mb-2" placeholder='Indirizzo email'
          prepend-inner-icon="$at">
        </v-text-field>

        <!-- PASSWORD -->
        <div class="text-subtitle-1 d-flex align-center justify-space-between">
          Password
          <router-link :to="{ name: 'forgot-password' }" class="text-caption text-decoration-none text-blue"
            rel="noopener noreferrer">
            Password dimenticata?</router-link>
        </div>

        <v-text-field v-model="password" color="primary" name="password" :type="visible ? 'text' : 'password'"
          :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='current-password' class="mb-2"
          placeholder='Inserisci la password' prepend-inner-icon='$lock'>
          <template #append-inner>
            <v-icon v-show='password' :icon="visible ? '$eye-off' : '$eye'" @click="visible = !visible"></v-icon>
          </template>
        </v-text-field>

        <v-btn type="submit" color="primary" size="large" rounded :loading='$api.isLoading && formValid'
          :disabled='!formValid' block>Sign In</v-btn>

        <div class="mt-4 text-right">
          <router-link :to="{ name: 'singup' }" class="text-caption text-decoration-none text-blue"
            rel="noopener noreferrer">Non sei registrato? Clicca qui</router-link>
        </div>
      </v-card-item>

    </v-form>

    <!-- <pre>{{ identityStore.user }}</pre>
    <pre>{{ clientSessionStore.getSessionId }}</pre>-->
  </v-card>

</template>

<script setup lang='ts'>

import { ref } from 'vue';
import { Api } from '@/_api';

import { useUserStore } from '@/store/identityStore';
//import { useClientSessionStore } from '@/store/clientSessionStore';
//const clientSessionStore = useClientSessionStore();


// https://spike-nuxtjs-free.netlify.app/auth/login

const rules = {
  required: value => !!value || 'Campo obbligatorio',
}

const visible = ref(false);
const formValid = ref(false);
</script>

<script lang='ts'>

export default {
  name: "user-singin",
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    login() {
      const vm = this;
      const identityStore = useUserStore();

      var dto = Api.LoginRequestDto.fromJS({
        email: this.username,
        password: this.password,
        rememberMe: true
      })!;

      this.$api.AuthenticationClient.onPostAuthenticate(dto).then((response) => {
        if (!response.user) throw new Error('Risposta non formattata correttamente');

        identityStore.setUser(response.user);
        vm.$router.push({ name: response.redirect?.name });
      })
    },
  },
  created() {
  }
}
</script>
