<template>

  <v-card max-width="500" rounded="lg" class="mx-auto pa-5" elevation="2" color="grey-lighten-4">

    <v-card-title class='pa-4'>
      Password dimenticata
    </v-card-title>

    <v-form @submit.prevent="onSubmit" v-model="formValid">
      <v-card-item>

        <div class='text-medium-emphasis mb-3' v-if='!confirmMessage'>
          Inserisci il tuo indirizzo email, se è presente nel sistema riceverai una email con le istruzioni per
          resettare la password.
        </div>
        <div v-else class='text-green-darken-2 mb-3'>
          Se l'account corrispondente all'indirizzo email fornito esiste, ti saranno inviate le istruzioni per il
          reset della password.
        </div>

        <!-- Email Field -->
        <v-text-field v-model="email" placeholder="Indirizzo Email" :rules="[rules.required, rules.validEmail]"
          :readonly='$api.isLoading' required autocomplete="email" variant='outlined' bg-color='white'></v-text-field>

        <!-- Submit Button -->
        <v-btn type="submit" color="primary" size="large" class='mt-4' block :disabled="!formValid || $api.isLoading"
          :loading='$api.isLoading'>
          Richiedi Reset Password
        </v-btn>
      </v-card-item>
    </v-form>
  </v-card>

</template>

<script setup lang='ts'>
import { Api } from '@/_api';
import { ref } from 'vue';

// Definizione delle regole di validazione
const rules = {
  required: value => !!value || 'Campo obbligatorio',
  validEmail: value => /.+@.+\..+/.test(value) || 'Email non valida',
};

const formValid = ref(false);

// Definizione degli eventi
const emit = defineEmits(['reset-request']);

const submitResetRequest = () => {
  // Qui puoi aggiungere la logica per la richiesta di reset della password

};
</script>

<script lang='ts'>

export default {
  name: "user-forgot-password",
  data() {
    return {
      email: null,
      confirmMessage: false,
    }
  },
  methods: {
    onSubmit() {
      const vm = this;
      if (this.$api.isLoading) return;

      var dto = Api.ForgotPasswordRequest.fromJS({ email: this.email })!;

      this.$api.AuthenticationClient.forgotPassword(dto)
        .then(response => {
          vm.confirmMessage = true;


          console.log(response);
        })
        .catch(error => {
          console.error(error);
        })

      //this.$emit('reset-request', email.value);
    },
  },
  created() {
    // TODO: nuovo componente?
    const encoded = this.$route.query.token;
    if (encoded != undefined) {
      //console.log('Token:', encoded);
      //var dto = JSON.parse(encoded);


    }
  }
}
</script>
