
import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from "@/router/middleware/loadLayoutMiddleware";

import NotFound from '../views/NotFound.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Index from '../views/Index.vue'
import WebRequestView from '../views/WebRequest.vue'
import UsersIndexView from '../views/UsersIndexView.vue'
import TableServerAsync from '../views/TableServerAsync.vue'
import LoginView from '../views/User/LoginView.vue'
import RegistrationView from '../views/User/RegistrationView.vue'
import ForgotPasswordView from '../views/User/PasswordForgotView.vue'
import PasswordResetView from '../views/User/PasswordResetView.vue'
import UserProfileView from '../views/User/UserProfileView.vue'
import DashboardView from '../views/DashboardView.vue'


const routes = [
  {
    path: '/', name: 'root', component: Index, meta: { layout: 'default' },
    children: [
      { path: '/hello', component: HelloWorld, meta: { layout: 'fullpage' } },
      { path: '/web', name: 'web', component: WebRequestView },
      { path: '/dashboard', name: 'dashboard', component: DashboardView },
      { path: '/users', name: 'users', component: UsersIndexView },
      { path: '/user/profile', name: 'profile', component: UserProfileView },
      { path: '/user/login', name: 'login', component: LoginView, meta: { layout: 'fullpage' } },
      { path: '/user/singup', name: 'singup', component: RegistrationView },
      { path: '/user/forgot-password', name: 'forgot-password', component: ForgotPasswordView },
      { path: '/user/reset-password/:token?', name: 'reset-password', component: PasswordResetView },
      { path: '/table/async', name: 'table-async', component: TableServerAsync },
    ]
  },
  { path: '/:pathMatch(.*)*', name: '404', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})



router.beforeEach((to, from, next) => {
  //const keepAlive = !!to.meta.keepAlive;
  const toDepth = to.fullPath.split('/').filter(n => n).length;
  const fromDepth = from.fullPath.split('/').filter(n => n).length;
  const newTransition = toDepth < fromDepth ? 'slide-left' : 'slide-right';

  to.meta.transition = newTransition;
  console.log('navigating', from.fullPath, 'to', to.fullPath, 'transition', newTransition);

  next();
});

export default router
