
import { App, shallowRef, defineAsyncComponent, AsyncComponentLoader } from 'vue';
import { Router } from 'vue-router';

export default {
  async install(app: App, options?: { router?: Router }) {

    if (!options || !options.router) throw new Error('Option router not provided');

    const modules = import.meta.glob('@/layouts/*.vue', { eager: true });
    //const modulesLazy = import.meta.glob('../layouts/*.vue', { eager: false });

    for (const fp in modules) {
      const name = fp.split('/').pop()?.split('.').slice(0, -1).join('.'); // file
      if (!name) throw new Error(`Invalid template ${fp} > ${name}`);
      modules[name] = modules[fp];
    }

    const layouts = modules; // da valutare come si comporta in caso di hot-reaload

    const layout = shallowRef();

    app.config.globalProperties.$layout = layout;
    app.provide('app:layout', layout);


    options.router.afterEach(async to => {
      const layoutName = to.meta.layout as string || 'default';

      const module = layouts[layoutName];
      if (!module) throw new Error(`Layout ${layoutName} not found`);
      // @ts-ignore
      layout.value = module.default; // vscode non riconosce il tipo
      //return;

      //const layoutComponent = getLayoutComponent(layoutName);
      //console.log(layoutName, layoutComponent);
      //layout.value = await defineAsyncComponent(layoutComponent); // caricare i file async crea uno sfarfallio

      //switch (to.meta.layout) {
      //  case 'default': layout.value = default_; break;
      //  case 'fullpage': layout.value = fullpage_; break;
      //  default: break;
      //}
      //return;

    });


    //function getLayoutComponent(layoutName: string): AsyncComponentLoader | undefined {
    //  const layoutFile = `../layouts/${layoutName}.vue`;
    //  console.warn(layoutFile, modulesLazy, modulesLazy[layoutFile])
    //  return modulesLazy[layoutFile];
    //}
  }

  // Optionally, register more logic or components related to layout management
}
