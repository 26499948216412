<template>
  <Registration></Registration>
</template>

<script setup lang='ts'>

import Registration from '@/components/User/Registration.vue';

</script>

<script lang='ts'>
import { ref } from 'vue';

export default {
  name: "login-view",
  components: {
    Registration
  },
  data() {
    return {

    }
  },
  methods: {

  },
  created() {
  }
}
</script>
