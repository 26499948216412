<template>
  <v-container>

    <div class="d-flex align-center" style="height: 80vh">

      <v-row align="center" justify="center">

        <v-col class='justify-center'>
          <h1 class='text-center text-h1 font-weight-black'>FAKE CRM</h1>
        </v-col>


        <v-col>
          <LoginComponent></LoginComponent>
        </v-col>

      </v-row>



    </div>

  </v-container>
</template>

<script setup lang='ts'>

import LoginComponent from '@/components/User/Login.vue';

</script>

<script lang='ts'>
import { ref } from 'vue';

export default {
  name: " login-view", components: { LoginComponent }, data() { return {} }, methods: {}, created() { }
} </script>
