// Plugins
import vuetify from './vuetify'
import pinia from '../store'
import router from '../router/index.classic'
import notifications from './toast'
import httpService from './httpService'
import VueApexCharts from "vue3-apexcharts";

import LayoutPlugin from './layoutPlugin'

// Types
import type { App } from 'vue'
//import { createNotivue } from "notivue";
//const notivue = createNotivue()


// LAYOUT SYSTEM
// LAYOUT SYSTEM
// LAYOUT SYSTEM
// TODO: fai un plugin

//import layouts from '@/layouts/layouts.js'
//import { provide, shallowRef } from 'vue'
//const layout = shallowRef('div');
//
//router.afterEach(to => {
//  // TODO: check valid layout
//  //console.warn(to.meta.layout, layouts[to.meta.layout]);
//  layout.value = layouts[to.meta.layout] || 'div';
//});

// LAYOUT SYSTEM
// LAYOUT SYSTEM
// LAYOUT SYSTEM


export function registerPlugins(app: App) {
  app
    .use(LayoutPlugin, { router })
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(notifications)
    .use(httpService, { baseUrl: import.meta.env.VITE_BASE_API_URL })
    .use(VueApexCharts) // make <apexchart> component available everywhere

  //app.provide('app:layout', layout); // LAYOUT SYSTEM
}


// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event: Event) => {
  console.error('preloadError', event)
  window.location.reload();
});
