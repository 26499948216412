<template>
  <v-navigation-drawer v-model="drawer">
    <v-container>

      <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg" title="John Leider" nav eager>
      </v-list-item>

      <v-divider />

      <v-list density="compact" nav>
        <v-list-item prepend-icon="$view-dashboard" title="Dashboard" value="Dashboard"
          :to="{ name: 'dashboard' }"></v-list-item>
        <v-list-item prepend-icon="$homeCity" title="Home" value="home"></v-list-item>
        <v-list-item prepend-icon="$account" title="Account" value="account"></v-list-item>
        <v-list-item prepend-icon="$accountGroupOutline" title="Utenti" value="users"
          :to="{ name: 'users' }"></v-list-item>
      </v-list>
    </v-container>
  </v-navigation-drawer>

  <MainAppBar :prop-drawer="drawer" @update-data="updateData">

    <v-container class="mx-auto d-flex align-center justify-center">
      <v-btn v-for=" link in links" :key="link" :text="link" variant="text"></v-btn>
      <v-spacer></v-spacer>

      <ProfileBadge>
      </ProfileBadge>
    </v-container>

  </MainAppBar>

  <v-main>

    <v-container>

      <slot />

    </v-container>
  </v-main>

  <v-footer app>
    <pre class='noselect'>foooter</pre>
  </v-footer>


</template>

<script lang="ts" setup>

</script>

<script lang="ts">
import { lifecycleLoggerMixin } from '../mixins/lifecycleLoggerMixin';

import { useDisplay } from 'vuetify'

import MainAppBar from '@/components/MainAppBar.vue';
import ProfileBadge from '@/components/ProfileBadge.vue';


export default {
  name: "app-layout-default",
  //mixins: [lifecycleLoggerMixin],

  components: {
    MainAppBar,
    ProfileBadge,
  },
  data() {
    return {
      drawer: Boolean(null),
      elevation: 0,
      links: [
        'Dashboard',
        'Profile',
      ],
    };
  },
  methods: {
    updateData(newValue) {
      this.drawer = newValue;
    }
  },
  created() {
    const { width, mobile } = useDisplay()
    this.drawer = !mobile.value;
  },
}
</script>


<style scoped>
.translucid {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(11px) grayscale(30%);
}

.Notivue__notification {
  --nv-gap: 1rem;
  --nv-z: 2000;
}
</style>
