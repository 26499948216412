import { App } from 'vue';
import axios, { AxiosInstance } from 'axios';
import { setupInterceptorsTo } from "./interceptors";
import { loading } from './loading';

import { Api as ApiClients } from '../_api';

import { useClientSessionStore } from '@/store/clientSessionStore';
import { useUserStore } from '@/store/identityStore';
import LogRocket from 'logrocket';

let _api: ApiClientsType;
let _http: AxiosInstance;

export const getApi = () => _api;
export const getHttp = () => _http;

export default {
  install: (app: App, options: PluginOptions) => {
    console.log('Axios base url', options.baseUrl)

    const clientSessionStore = useClientSessionStore();
    const identityStore = useUserStore();

    //    LogRocket.init('eil56t/frontend'); da attivare solo in release

    if (identityStore.user.id) {
      LogRocket.identify(identityStore.user.id, {
        email: identityStore.user.email ?? '',
        name: identityStore.displayName || ''
      });
    }

    const axiosClient: AxiosInstance = axios.create({
      baseURL: options.baseUrl,
      maxRedirects: 3,
      withCredentials: true, // richiesto solo in sviluppo? - https://stackoverflow.com/questions/43002444/make-axios-send-cookies-in-its-requests-automatically
      headers: {
        'X-Client-ID': clientSessionStore.sessionId,
        //'X-Request-ID': uuidv4(),
        //'X-Requested-With': 'XMLHttpRequest', // rompe le chiamate upload file?
      },
      //transformRequest: [function (data) {
      //  return stringify(data);
      //}],
      //transformResponse: [
      //  function (data) {
      //    console.warn('stupid type', typeof data, 'value:', data)
      //    //return data;
      //    if (!data) data = '{}';
      //    return fromJSON(data);
      //  },
      //  ...axios.defaults.transformResponse,
      //]
    });

    //console.warn(axiosClient.defaults.transformRequest);
    //console.warn(axiosClient.defaults.transformResponse);

    const apiloading: Partial<ApiClientsType> = { get isLoading() { return loading.isLoading } };

    for (const [key, TClass] of Object.entries(ApiClients)) {
      if (key.endsWith('Client') && typeof TClass === 'function') {
        // @ts-ignore
        apiloading[key as keyof ApiClientsType] = new TClass(options.baseUrl, axiosClient, null, null, null) as any;
      }
    }

    setupInterceptorsTo(axiosClient);

    // Assegnazione delle proprietà globali
    _api = app.config.globalProperties.$api = apiloading as ApiClientsType;
    _http = app.config.globalProperties.$http = axiosClient;
  }
}

interface PluginOptions {
  baseUrl: string;
}

// per includere tutti i tipi: type ApiClientsType = typeof ApiClients;
type ApiClientsType = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof typeof ApiClients as K extends `${infer T}Client` ? K : never]:
  typeof ApiClients[K] extends new (baseUrl: string, instance: AxiosInstance) => infer R ? R : never;
} & {
  isLoading: boolean;
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: ApiClientsType;
    $http: AxiosInstance;
  }
}
