/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
//import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'
import '@/styles/settings.scss' // < for custom variables

//import 'typeface-roboto/index.css';

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify'
//import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import LuxonAdapter from '@date-io/luxon'
const luxon = new LuxonAdapter({ locale: 'it' });

import {
  mdiAccount,
  mdiAccountGroupOutline,
  mdiChevronLeft, mdiChevronRight,
  mdiHomeCity,
  mdiAt,
  mdiEye,
  mdiEyeOff,
  mdiPhone,
  mdiLock,
  mdiViewDashboard
} from '@mdi/js'

// https://vuetifyjs.com/en/features/theme/#typescript
// https://vuetifyjs.com/en/styles/colors/#material-colors

// TODO: impostare il testo a #2a3547
const f2LightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'on-surface': '#252032',
    background: '#e4ecf9',
    //surface: '#FFFFFF',
    primary: '#0a8adc',
    //'primary-darken-1': '#3700B3',
    //secondary: '#03DAC6',
    //'secondary-darken-1': '#018786',
    //error: '#B00020',
    //info: '#2196F3',
    //success: '#4CAF50',
    //warning: '#FB8C00',
  },
  variables: {
    //'border-color': '#000000',
  }
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// https://vuetifyjs.com/en/features/theme/#javascript
// edit efaults https://vuetifyjs.com/en/features/global-configuration/
export default createVuetify({
  ssr: false,
  theme: {
    // cspNonce: 'dQw4w9WgXcQ', // https://vuetifyjs.com/en/features/theme/#csp-nonce
    defaultTheme: 'f2LightTheme', //'light',
    themes: {
      f2LightTheme,
    },
  },
  defaults: {
    global: {
      ripple: true,
    },
    VBtn: {
      variant: 'tonal',
      elevation: 0,
    },
    VSwitch: {
      variant: 'inset',
      color: 'primary'
    },
    VTextField: {
      rounded: 4,
      variant: 'outlined',
      //variant: 'solo-filled',
      //flat: true,

      class: 'mb-2',
      minWidth: 60,
    },
    VTextarea: {
      variant: 'solo-filled'
    },
    VDataTable: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },
    VDataTableServer: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      account: mdiAccount,
      'chevron-left': mdiChevronLeft,
      'chevron-right': mdiChevronRight,
      homeCity: mdiHomeCity,
      accountGroupOutline: mdiAccountGroupOutline,
      at: mdiAt,
      eye: mdiEye,
      'eye-off': mdiEyeOff,
      phone: mdiPhone,
      lock: mdiLock,
      'view-dashboard': mdiViewDashboard,
    },
    sets: {
      mdi,
    },
  },
  date: {
    adapter: luxon
  }
})
