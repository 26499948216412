<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" location="right" :offset="[8, 0]">
      <template v-slot:activator="{ props }">
        <v-btn fab icon width="auto" height="auto" v-bind="props" class='mr-4'>
          <v-badge :content="1" color='error'>
            <v-avatar color="blue" class='noselect nodrag'>
              <v-img v-if='identityStore.user.avatarSrc' alt="Avatar" :src='identityStore.user.avatarSrc' />
              <span v-else class="text-h7">{{ identityStore.getInitials }}</span>
            </v-avatar>
          </v-badge>
        </v-btn>
      </template>

      <v-card min-width="300">

        <!--<div class="v-responsive v-img bg-surface-light" flat style="height: 88px;"></div>-->
        <v-img :height="88" cover draggable='false' eager
          src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"></v-img>

        <div class="text-center mt-n9 mb-4">
          <div>
            <v-avatar color="blue" class='noselect nodrag elevation-1' size='72'>
              <v-img v-if='identityStore.user.avatarSrc' alt="Avatar" :src='identityStore.user.avatarSrc' />
              <span v-else class="text-h4">{{ identityStore.getInitials }}</span>
            </v-avatar>

            <div class="mt-2 text-subtitle-1">{{ identityStore.displayName }}</div>
            <div class="text-caption">{{ identityStore.user.email }}</div>
          </div>
        </div>

        <!--
        <v-list>
          <v-list-item :subtitle="identityStore.user.email" :title="identityStore.displayName">

            <template v-slot:prepend>
              <v-avatar color="blue" class='noselect nodrag'>
                <v-img v-if='identityStore.user.avatarSrc' alt="Avatar" :src='identityStore.user.avatarSrc' />
                <span v-else class="text-h7">{{ identityStore.getInitials }}</span>
              </v-avatar>
            </template>

            </!--
            <template v-slot:append>
              <v-btn :class="fav ? 'text-red' : ''" icon="mdi-heart" variant="text" @click="fav = !fav"></v-btn>
            </template>
            --/>
        </v-list-item>
        </v-list>-->

        <v-divider></v-divider>

        <v-list>

          <v-list-item>
            <v-btn color='primary' block>Impostazioni</v-btn>
          </v-list-item>

          <v-list-item>
            <v-btn color='primary' block :to='{ name: "profile" }'>Account</v-btn>
          </v-list-item>


          <v-list-item>
            <v-switch v-model="message" color="purple" label="Enable messages" hide-details></v-switch>
          </v-list-item>

          <v-list-item>
            <v-switch v-model="hints" color="purple" label="Enable hints" hide-details></v-switch>
          </v-list-item>

          <v-spacer></v-spacer>

          <v-list-item v-if='identityStore.user.id'>
            <v-btn color='warning' block v-on:click='identityStore.logout()'>Logout</v-btn>
          </v-list-item>

          <v-list-item v-else>
            <v-btn color='green-darken-4' block :to="{ name: 'login' }" v-on:click='menu = false'
              :disabled='!menu'>Login</v-btn>
          </v-list-item>

        </v-list>

        <!--<v-card-actions>
          <v-spacer></v-spacer>

          <v-btn variant="text" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn color="primary" variant="text" @click="menu = false">
            Save
          </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useUserStore } from '@/store/identityStore';
const identityStore = useUserStore();


const fav = ref(true)
const menu = ref(false)
const message = ref(false)
const hints = ref(true)
</script>

<script>
export default {
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
}
</script>
